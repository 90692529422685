<template>
  <div class="storyItem">
    <router-link
      :to="{
        name: 'CultureStoryDetail',
        query: { id: item.msId, page: currentPage }
      }"
    >
      <div class="clickable">
        <div
          :alt="`${item.title}`"
          class="storyItem__img"
          :style="`background-image: url(${baseURL}${item.image})`"
        ></div>

        <h3 class="storyItem__title">{{ item.title }}</h3>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        image: 'upload/mining_story/0bb78b92fd66.jpg',
        location: '九份',
        msId: '13',
        order: '0',
        title: '在九份，拼貼風景的人'
      })
    },
    currentPage: {
      type: Number,
      default: 1
    },
    location: {
      type: String,
      default: ''
    }
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_WEB_BASE
    }
  }
}
</script>

<style lang="scss">
.storyItem {
  width: 33.33%;
  padding: 1rem;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));

  &__img {
    padding-bottom: 75%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  &__title {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 500;
    padding-left: 1.6rem;
    margin-top: 1.25rem;
  }
}

@media (max-width: 768px) {
  .storyItem {
    width: 100%;
    padding: 0;
    padding-bottom: 3.5rem;
  }
}
</style>

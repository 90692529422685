<template>
  <section class="cultureStory container">
    <CultureStoryFilter @onChangeFilter="onChangeFilter" :location="location" />

    <div class="cultureStory__itemContainer">
      <StoryItem
        v-for="story in mapFilterWithPagination"
        :key="story.smId"
        :item="story"
        :currentPage="currentPage"
        :location="location"
      />
    </div>

    <b-pagination-nav
      v-model="currentPage"
      :number-of-pages="Math.ceil(this.mapFilterWithLocation.length/9).toString()"
      :base-url="'?location=' + location + '&page='"
      use-router
      align="center"
      size="lg"
      first-number
      last-number
    ></b-pagination-nav>

    <hr class="cultureStory__hr" />
  </section>
</template>

<script>
import CultureStoryFilter from '@/components/Culture/Story/CultureStoryFilter.vue'
import StoryItem from '@/components/Culture/Story/StoryItem.vue'
import BasePagination from '@/components/BasePagination.vue'

// Api
import { apiGetMiningStory } from '@/api/webAPI'
import $ from "jquery";

export default {
  components: {
    CultureStoryFilter,
    StoryItem,
    BasePagination
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 9,
      storyList: [],
      location: ''
    }
  },
  computed: {
    mapFilterWithLocation() {
      if (!this.location) return this.storyList
      return this.storyList.filter(story => story.location === this.location)
    },
    mapFilterWithPagination() {
      const pageSize = this.pageSize
      const startIndex = pageSize * (this.currentPage - 1)
      const endIndex = pageSize * this.currentPage

      return this.mapFilterWithLocation.slice(startIndex, endIndex)
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      const { page, location } = this.$route.query
      if (page) this.currentPage = +page
      this.location = location || ''

      if (this.storyList.length !== 0) return

      const response = (
        await apiGetMiningStory({
          type: 'category'
        })
      ).data.data

      this.storyList = response
    },
    onCurrentPageChange(page) {
      const { query } = this.$route
      this.$router.push({
        name: 'CultureStory',
        query: {
          ...query,
          page: page
        }
      })
    },
    onChangeFilter(filter) {
      this.$router.push({
        name: 'CultureStory',
        query: {
          page: 1,
          location: filter.value
        }
      })
    }
  },
  created() {
    this.fetchData()
  },
  mounted(){
    $('ul.pagination >:first-child').attr('title', '上一頁')
    $('ul.pagination >:last-child').attr('title', '下一頁')
    // $("button").removeAttr('tabindex');
  }
}
</script>

<style lang="scss">
.cultureStory {
  padding: 6.9rem 0;

  &__itemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10rem;
  }

  &__hr {
    border: none;
    border-bottom: 1px dashed #d0a805;
    width: 100%;
    margin: 10.4rem 0 10rem 0;
  }
}
</style>

<template>
  <ul class="cultureStoryFilter">
    <a href="#"
      v-for="filter in filterList"
      :key="filter.title"
      class="cultureStoryFilter__item"
      :class="
        location === filter.value ? 'cultureStoryFilter__item--active' : ''
      "
      :style="{ '--bgColor': filter.bgColor }"
      @click="onClickFilter(filter)"
    >
      <li>
        <p class="cultureStoryFilter__title">{{ filter.title }}</p>
      </li>
    </a>
  </ul>
</template>

<script>
export default {
  props: {
    location: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      filterList: [
        {
          title: '全部',
          value: '',
          bgColor: '#D0AA05'
        },
        {
          title: '水湳洞',
          value: '水湳洞',
          bgColor: '#D3E1C8'
        },
        {
          title: '金瓜石',
          value: '金瓜石',
          bgColor: '#CFDFDF'
        },
        {
          title: '九份',
          value: '九份',
          bgColor: '#E4E4DE'
        }
      ]
    }
  },
  methods: {
    onClickFilter(filter) {
      if (this.location === filter.value) return
      this.$emit('onChangeFilter', filter)
    }
  }
}
</script>

<style lang="scss">

.cultureStoryFilter {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 9.5rem;

  &__item {
    position: relative;
    width: 102px;
    height: 0;
    border-width: 0 51px 20px;
    border-style: solid;
    border-color: transparent transparent var(--bgColor);
    margin: 1rem;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.6rem;
    word-break: keep-all;
    transition: all 0.3s ease-in-out;
  }

  &__item::before {
    border-color:red;
    position: absolute;
    content: '';
    top: 20px;
    left: -51px;
    width: 100px;
    height: 0;
    background: none;
    border-width: 53px 1px 0;
    border-style: solid;
    border-color: var(--bgColor) var(--bgColor) transparent;
  }

  &__title {
    width: 102px;
    transform: translateX(-50%) translateY(100%);
    text-align: center;
  }

  &__item:hover,
  &__item--active {
    cursor: pointer;
    transform: translateY(-12px);
  }

  &__item:hover::after,
  &__item--active::after {
    position: absolute;
    content: '';
    left: 0;
    transform: translateX(-50%);
    top: 78px;
    display: block;
    background-color: #d3d4d4;
    width: 6.596rem;
    height: 0.81rem;
    border-radius: 75%;
  }
}

@media (max-width: 768px) {
  .cultureStoryFilter {
    &__item {
      width: 73px;
      border-width: 0 36.5px 10px;
      margin: 0.7rem;
    }

    &__item::before {
      top: 10px;
      left: -39px;
      width: 73px;
      border-width: 30px 1px 0;
    }

    &__title {
      width: 73px;
      transform: none;
      transform: translateX(-50%) translateY(40%);
    }

    &__item:hover::after,
    &__item--active::after {
      top: 46px;
      width: 4.27rem;
    }
  }
}
</style>
